<template>
  <RenovarChaveApi />
</template>

<script>
import RenovarChaveApi from "@/components/gestao/api/RenovarChaveApi.vue";

export default {
  components: {
    RenovarChaveApi
  }
};
</script>
